import React from 'react';
import { scaleOrdinal } from '@visx/scale';
import {
  Legend,
  LegendItem,
  LegendLabel,
} from '@visx/legend';
import useWindowSize from './hooks';

const ordinalColor2Scale = scaleOrdinal({
  domain: ['a', 'b', 'c', 'd'],
  range: ['#fae856', '#f29b38', '#e64357', '#8386f7'],
});

const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
  domain: ['Ongoing Projects'],
  range: [
      () => (
        <text key="e" fontSize="18" dy=".8em" dx=".33em" fill="#e0a346">
         🏗
        </text>
      ),
  ],
});

const legendGlyphSize = 18;

export default function Example({ events = false }: { events?: boolean }) {
  const { width } = useWindowSize();

  return (
    <div className="legends">
        <LegendDemo title="Shortcuts">
        <Legend scale={shapeScale}>
          {(labels) => (
            <div>
                <LegendItem>
                    <LegendLabel align="left" margin={0}>
                           {width && width < 900 ? "🤌 Pinch to Zoom" : "Control + Scroll to Zoom"}
                    </LegendLabel>
                </LegendItem>
            </div>
          )}
        </Legend>
      </LegendDemo>
      <LegendDemo title="Legend">
        <Legend scale={shapeScale}>
          {(labels) => (
            <div>
              {labels.map((label, i) => {
                const color = ordinalColor2Scale(label.datum);
                const shape = shapeScale(label.datum);
                const isValidElement = React.isValidElement(shape);
                return (
                  <LegendItem
                    key={`legend-quantile-${i}`}
                    margin="0 0 4px 0"
                    onClick={() => {
                      const { datum, index } = label;
                      if (events) alert(`clicked: ${JSON.stringify({ datum, color, index })}`);
                    }}
                  >
                    <svg
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                      style={{ margin: '0 8px 0 0' }}
                    >
                      {isValidElement
                        ? React.cloneElement(shape as React.ReactElement)
                        : React.createElement(shape as React.ComponentType<{ fill: string }>, {
                            fill: color,
                          })}
                    </svg>
                    <LegendLabel align="left" margin={0}>
                      {label.text}
                    </LegendLabel>
                  </LegendItem>
                );
              })}
            </div>
          )}
        </Legend>
      </LegendDemo>

      <style jsx>{`
        .legends {
          font-weight: 900;
          border-radius: 14px;
          padding: 24px 24px 24px 32px;
          overflow-y: auto;
          flex-grow: 1;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        @media screen and (max-width: 900px) {
            .legends {
              display: flex;
              flex-direction: column;
              left: 0;
              right: auto;
              padding: 24px 12px 24px 12px;
            }
          }
        .chart h2 {
          margin-left: 10px;
        }

        .visx-legend-label {
            font-family: "Source Code Pro";
            font-weight: 'regular';
        }
      `}</style>
    </div>
  );
}

function LegendDemo({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <div className="legend">
      <div className="title">{title}</div>
      {children}
      <style jsx>{`
        .legend {
          line-height: 0.9em;
          color: #222;
          font-size: 16px;
          padding: 10px 10px;
          float: left;
          border: 1px solid #eee;
          border-radius: 8px;
          margin: 5px 5px;
          background-color: rgb(255, 253, 241, 0.3);
          backdrop-filter: blur(8px);
          -webkit-backdrop-filter: blur(8px);
        }
        .title {
          font-size: 12px;
          font-family: "Source Code Pro";
          margin-bottom: 10px;
          font-weight: 100;
        }
      `}</style>
    </div>
  );
}
