import * as markmap from 'markmap-view';
import { Transformer } from 'markmap-lib';
const axios = require('axios');

const transformer = new Transformer();
const { Markmap, loadCSS, loadJS } = markmap;

const mindMapStyles = `
    .markmap {
        font: 300 16px/20px 'Source Code Pro';
    }
    .markmap strong {
        font: 500 16px/20px 'Commissioner';
    }
    .markmap-foreign a {
        color: #3A80D7;
        font-weight: 400;
    }
    .markmap-link {
        stroke: #E9AEA4;
        stroke-width: 2px;
    }
    .markmap-node line {
        stroke: #E9AEA4;
        stroke-width: 2px;
    }
    .markmap-node circle {
        stroke: #D583C0;
        r: 8;
        stroke-width: 2px;
        fill: #fffdf1;
    }
`;

axios.get(process.env.GATSBY_MINDMAP_URL)
    .then(res => {
        const { root, features } = transformer.transform(res.data.code.text[0].plain_text);
        const { styles, scripts } = transformer.getUsedAssets(features);

        if (styles) loadCSS(styles);
        if (scripts) loadJS(scripts, { getMarkmap: () => markmap });

        if (typeof document !== `undefined` && typeof window !== `undefined`) {
            try {
                Markmap.create('#mindmap', {
                    style: () => mindMapStyles
                }, root);
            } catch (err) {
                console.log(err)
            }
        }
    })