import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/SEO/seo';
import LegendExample from '../components/Legend'
import '../components/MindMap/mindmap.js'

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
);
  return (
    <Layout fullheight nobottommargin notopmargin mainpage>
      <SEO lang="en" description="Artyom Alekseev — Product Designer, frontend develop and person who love to do what he love." title={'Main'} keywords={['designer', 'artyom', 'alekseev', 'Artyom Alekseev', 'ux', 'ui']}/>
      <LegendExample />
      <svg fill={`url('#pattern')`} style={{display: "block", width: '100%', height: '100%', fontWeight: 300}} id="mindmap"/>
    </Layout>

  ); };

export default IndexPage;
